.Shma {
  display: flex;
  flex-direction: column;
}

.Shma > h1 {
  text-align: center;
}

.Shma-paragraph {
  border-bottom: 1px solid #a3a3a3;
  margin-bottom: 2rem;
}

.Shma-hebrew {
  font-family: 'Times New Roman', Times, serif;
  font-size: larger;
}