.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.FlashCard {
  border: 1px solid #a3a3a3;
  padding: 2rem;
  width: 300px;
  height: 300px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FlashCard-hebrew {
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  font-family: 'Times New Roman', Times, serif;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-style: dotted;
  margin-block-start: 24px;
  margin-block-end: 24px;
}

.FlashCard-play {
  margin-top: 1rem;
}

.FlashCard-english {
  box-sizing: border-box;
  display: block;
  height: 32px;
}

.App-link {
  box-sizing: border-box;
  height: 32px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  margin-top: 2rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: blue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
