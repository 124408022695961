.AppBar {
  position: relative;
  background-color: #eaeaea;
  border: 1px solid #a3a3a3;
  box-shadow: inset;
}

.AppBar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
}

.AppBar-header > h1 {
  text-align: center;
  font-size: 1.2rem;
  flex-grow: 1;
}

.hamburger-menu {
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 10px;
}

.hamburger-menu:hover {
  background-color: #ddd;
  z-index: 2000;
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #f3f3f3;
  box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
  padding: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1999;
}

.drawer a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: block;
  margin: 5px 0;
}

.drawer a:hover {
  background-color: #ddd;
}

.close-button {
  align-self: flex-end;
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0 10px;
}
