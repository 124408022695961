.HaftarahBrachotPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HaftarahBrachotPage-root {
  max-width: 800px;
}
.HaftarahBrachot-hebrew {
  font-family: 'Times New Roman', Times, serif;
  font-size: larger;
}

.HaftarahBrachot-bracha {
  margin-bottom: 2rem;
  border-bottom: 1px solid #a3a3a3;
}