.Layout-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.Layout-content {
  overflow-y: auto;
  flex-grow: 1;
  padding: 2rem;
}